.loginicon {
  min-width: 40px; 
  text-decoration: none;
  font-weight: bolder;
  color: black;
  height: 40px;
  line-height: 38px;
  text-align: center;
  background: #2bc6ff;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.loginicon p{
  border: 2px solid black;
  border-radius: 20px;
}

.loginicon:hover {
  color: white;
}
