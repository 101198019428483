.single-product-on-list img {
  max-width: 100px;
  margin-right: 1rem;
}

.product-list-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.single-product-on-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  transition: background-color 0.3s;
  cursor: pointer;
}

.single-product-on-list:hover {
  background-color: grey;
}

.product-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.product-details h2 {
  margin: 0;
}

.product-price-and-cart {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.product-price {
  margin: 0;
  text-align: right;
  white-space: nowrap;
}

.product-add-to-cart-btn {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  white-space: nowrap;
}

.product-add-to-cart-btn:hover {
  background-color: #0056b3;
}

.confirmation-message {
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #28a745; /* Green background for success */
  color: white;
  padding: 1rem 2rem;
  border-radius: 5px;
  z-index: 1000; /* Ensure it's on top of other elements */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: opacity 0.5s ease-in-out;
}
