.loading-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 100vw;

}
.loading-component {
    width: 50%;
    height: 50%;
    max-width: 100px;
    max-height: 100px;
}