.cart-container {
    max-width: 1264px;
    margin: 0 auto;
}

.d-flex {
    display: flex;
}

.flex-row {
    flex-direction: row;
}

.flex-column {
    flex-direction: column;
}

.flex-grow-1 {
    flex-grow: 1;
}

.flex-shrink-1 {
    flex-shrink: 1;
}

.justify-content-between {
    justify-content: space-between;
}

.cart-item-list {
    flex-grow: 1;
    flex-shrink: 1;
    padding: 0 1rem; 
}

.cart-summary {
    flex-basis: 25%;
    padding: 0 1rem; 
}

.basket-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.basket-item img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 20px;
}

.basket-item div {
    flex-grow: 1;
}

.basket-total {
    margin-top: 20px;
}

.checkout-button {
    background-color: #ff5a5f;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
}

.checkout-button:hover {
    background-color: #e04848;
}

.quantity-input {
    max-width: 100px;
}


.modal-content {
    border-radius: 10px;
  }
  
  .modal-header {
    border-bottom: 1px solid #dee2e6;
  }
  
  .modal-footer {
    border-top: 1px solid #dee2e6;
  }
