.dropdown-menu {
  transition: none;
  transform: none;
}

.dropdown-menu li {
    position: relative;
  }
  .dropdown-menu li:hover {
    background-color: #f0f0f0;
    color: #333;
    cursor: pointer;
  }
  .dropdown-menu .dropdown-submenu {
    display: none;
    position: absolute;
    left: 100%;
    top:-2px;
  }
  .dropdown-menu .dropdown-submenu-left {
    right: 100%;
    left: auto;
  }
  .dropdown-menu > li:hover > .dropdown-submenu {
    display: block;
  }