.category-tree {
    margin-left: 10px;
  }
  
  .associated-category {
    color: black;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  .category-children {
    margin-left: 0px;
  }
  
.associated-category:hover {
    color:rgb(0, 255, 34);
  }

  