.input-group-login {
  padding: 0 1rem 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  }
  
  .input-label-login {
    color: black;
    font-size: smaller;
    font-weight: bold;
    text-align: left; 
    width: 100%;
  }
  
  
.login-form-custom {
  width: auto;
  max-width: 300px;
  min-width: 200px;
  padding: 1rem 1rem 1rem 1rem;
  }