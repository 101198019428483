.search-btn {
  height: 50px !important;
  width: 50px !important;
  min-height: 50px;
  min-width: 50px;
  border-radius: 15px;
  overflow: hidden;
  padding: 0 !important;
  border: none;
  svg {
    width: 70%;
    height: 70%;
  }
}
.search-box {
  height: 100%;
}
.search-input {
  height: 100%;
  border-radius: 15px;
  overflow: hidden;
}
